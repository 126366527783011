import { Flex } from 'antd';
import { CDN_MARKETPLACE } from '../../../components/scene/assets/baseUrl';
import { memo } from 'react';
import { useRouter } from 'next/router';

const UnityLogo = ({ styles }) => {
  const router = useRouter();
  function redirectMain() {
    router.push('/');
  }
  return (
    <Flex align='center' gap={12} className={styles.zepeto}>
      <Flex gap={10} className={styles.zepetoWrapper} onClick={redirectMain}>
        <div className={styles.logoWrapper}>
          <img
            src={`${CDN_MARKETPLACE}/assets/logos/new-unity-logo.svg`}
            alt='Unity'
            className={styles.unityLogo}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default memo(UnityLogo);
