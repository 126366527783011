export const isDev = process.env.NEXT_PUBLIC_DEVELOPMENT === 'true';
export const AUTH_APP_URL = process.env.NEXT_PUBLIC_AUTH_APP_URL;
export const NEXT_PUBLIC_URL = process.env.NEXT_PUBLIC_URL;
export const CDN_IMAGES_URL = process.env.NEXT_PUBLIC_ASSETS_IMAGES_URL;

export const S3_IMAGES_URL = 'https://d3to9p4w6lww5g.cloudfront.net';
export const EDITOR_MENU_LOGOS_URL = `${S3_IMAGES_URL}/assets/`;

export const SIGN_IN_URL = isDev
  ? 'http://localhost:3001/login?origin=http://localhost:3000'
  : `${AUTH_APP_URL}/login?origin=${NEXT_PUBLIC_URL}`;

export const SIGN_IN_URL_NO_DEFAULT_REDIRECT = isDev
  ? 'http://localhost:3001/login?origin='
  : `${AUTH_APP_URL}/login?origin=`;

export const SIGN_UP_URL = isDev
  ? 'http://localhost:3001/signUp'
  : `${AUTH_APP_URL}/signUp`;

export const SIGN_UP_URL_NO_DEFAULT_REDIRECT = isDev
  ? 'http://localhost:3001/signUp?origin='
  : `${AUTH_APP_URL}/signUp?origin=`;

export const primaryColor =
  'radial-gradient(100% 100% at 50.46% 0%, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, #15C49A 0%, #086358 100%)';

export const flowScannerLink = isDev
  ? 'https://testnet.flowscan.org/transaction/'
  : 'https://flowscan.org/transaction/';

export const ADMIN_WALLET_ADDRESS =
  process.env.NEXT_PUBLIC_PRODUCTION === 'true'
    ? process.env.NEXT_PUBLIC_MAINNET_ADMIN_ACCOUNT_WALLET_ID
    : process.env.NEXT_PUBLIC_TESTNET_ADMIN_ACCOUNT_WALLET_ID;

export const METAVERSE_ADMIN_WALLET_ADDRESS =
  process.env.NEXT_PUBLIC_PRODUCTION === 'true'
    ? process.env.NEXT_PUBLIC_METAVERSE_MAINNET_ADMIN_ACCOUNT_WALLET_ID
    : process.env.NEXT_PUBLIC_METAVERSE_TESTNET_ADMIN_ACCOUNT_WALLET_ID;

export const METAVERSE_OLD_ADMIN_WALLET_ADDRESS =
  process.env.NEXT_PUBLIC_PRODUCTION === 'true'
    ? process.env.NEXT_PUBLIC_METAVERSE_OLD_MAINNET_ADMIN_ACCOUNT_WALLET_ID
    : process.env.NEXT_PUBLIC_METAVERSE_OLD_TESTNET_ADMIN_ACCOUNT_WALLET_ID;

export const IS_PROD =
  process.env.NEXT_PUBLIC_PRODUCTION === 'true' ? true : false;

export const prodLinks = {
  studio: 'https://studio.ozonemetaverse.io/',
  marketplace: 'https://marketplace.ozonemetaverse.io/',
  demos: 'https://ozonemetaverse.io/worlds',
  worlds: 'https://land.ozonemetaverse.io/',
};

export const marketplaceStaging = 'https://marketplace.o-zone.io/';
export const MARKETPLACE_BUCKET_URL =
  'https://ozone-marketplace-assets.s3.amazonaws.com/';
export const MARKETPLACE_CDN_URL = 'https://d3to9p4w6lww5g.cloudfront.net/';

export const imagePlaceholder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM88AIAAm4BqjmZp+MAAAAASUVORK5CYII=';

const stagingDistricts = {
  'c900267d-7289-4daa-aa4d-6cbe48a6668d': 'cyberpunk',
  '2e83f3f7-275e-41d4-a303-c06187061dfd': 'flowzone',
};

const prodDistricts = {
  '39245f76-457e-456b-8032-627689f8daf9': 'cyberpunk',
  'bc0265f5-3361-4c81-91bb-8372032794a5': 'cyberpunk',
  '0626766e-c9bf-4da7-99b6-be1f7b782a6f': 'cyberpunk',
  '5423bfdb-dce4-4b0c-ae53-82a626430e34': 'flowzone',
  'dbb99e84-5c18-4684-a6d2-29a7d755f35d': 'flowzone',
  '3f67a5ba-54ac-4bcf-a7b4-c6308cfda6be': 'flowzone',
  'ae9436da-c430-466d-9b6c-8721f9f7f388': 'flowzone',
};

export const districtsID =
  process.env.NEXT_PUBLIC_PRODUCTION === 'true'
    ? prodDistricts
    : stagingDistricts;

export const BRANDING = process.env.NEXT_PUBLIC_BRANDING ?? 'DEFAULT';

export const ANONYMOUS_SCENE_SUPPORT =
  process.env.NEXT_PUBLIC_ANONYMOUS_SCENE === 'true';

export const UNITY_EXPORT = 'true';
export const ASSETS_2022_CDN = 'https://d23hwblhugcea5.cloudfront.net';
export const AES_KEY = process.env.NEXT_PUBLIC_AES_KEY;

export enum EVENTS {
  SCENE_LOADED = 'scene_loaded',
}

export const DISABLE_CLICK_CLASS = 'disable-click-events';
export const DEFAULT_ERROR =
  'Something went wrong, please try again or contact us: support@ozonemetaverse.io';
export const DEFAULT_SCENE_PREVIEW = `${ASSETS_2022_CDN}/studioAssets/imageAssets/openAuditorium.jpg`;