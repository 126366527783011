import React, {
  useEffect,
  useState,
  useCallback,
  memo,
  useContext,
} from 'react';
import { Divider, Progress, Spin, Tooltip, Button, Flex, Dropdown } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import { trackUmamiEvent } from 'util/helpers';
import { CDN_MARKETPLACE } from '../scene/assets/baseUrl';
import Icon from '@ant-design/icons';
import Logo from '@src/theme/branding/Logo';
import NavigationButtonsGroup from './NavigationButtonsGroup';
import ProfileButtonsGroup from './ProfileButtonsGroup';
import ZepetoInstructionsModal from '../menu/zepetoInstructionsModal';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { IS_PROD, UNITY_EXPORT } from 'util/constants';
import styles from '../navigation-bar/navigation-bar.module.css';
import { BrandingContext } from 'pages/_app';

type Props = {
  isSignIn: boolean;
  userEmail: string;
  setIsSaveScene?: Function;
  isExportScene?: boolean;
  setIsExportScene?: Function;
  exportSceneProgress?: number;
  isLoginOpen?: boolean;
  setIsLoginOpen?: Function;
  setIsTourOpen?: Function;
  ref1?: any;
  ref2?: any;
  ref3?: any;
  ref4?: any;
  ref9?: any;
  ref10?: any;
  isCreator?: boolean;
  externalLogoURL?: string;
};

function ExportSceneProgress({ exportSceneProgress }) {
  return (
    <Tooltip
      title={
        <Flex align='center' gap={10}>
          Do you want to cancel?{' '}
          <Button
            onClick={() =>
              document.dispatchEvent(new CustomEvent('cancel_export'))
            }
          >
            Yes
          </Button>
        </Flex>
      }
      trigger='hover'
      color={'#f50'}
    >
      <span style={{ position: 'relative', cursor: 'pointer' }}>
        <Spin
          style={{ position: 'absolute', color: '#1677ff' }}
          indicator={<LoadingOutlined style={{ fontSize: 50, top: 7 }} spin />}
        />
        <Progress type='circle' percent={exportSceneProgress} size={50} />
      </span>
    </Tooltip>
  );
}

const MemoExportSceneProgress = memo(ExportSceneProgress);

function SaveIcon() {
  return (
    <img
      src={`${CDN_MARKETPLACE}/assets/logos/black-save-icon.svg`}
      alt='icon'
      width={17}
      height={17}
      style={{ marginTop: -2 }}
    />
  );
}

// This is a custom icon for ZepetoWeb export
const SaveIconZepetoWeb = () => {
  return (
    <img
      src={`${CDN_MARKETPLACE}/assets/logos/save-icon.svg`}
      alt='icon'
      width={17}
      height={17}
      style={{ marginTop: -2 }}
    />
  );
};

function UnityExportButtons({
  showZepetoInstructions,
  handleOzonePackageExport,
}) {
  const branding = useContext(BrandingContext);
  const items: MenuProps['items'] = [
    {
      key: '3',
      label: (
        <span
          className={styles.dropdownList}
          onClick={handleOzonePackageExport}
        >
          <Icon component={SaveIcon} />
          {UNITY_EXPORT && branding === 'ZEPETO' && (<span style={{ color: '#22222' }}> ZepetoWeb</span>)}
          {UNITY_EXPORT && branding === 'UNITY' && (<span style={{ color: '#22222' }}> UnityWeb</span>)}
        </span>
      ),
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <div className={styles.dropdownWrapper}>
      <Dropdown menu={menuProps} className={styles.mobileSaveButtons}>
        <Button className={styles.dropdownButton}>
          <Icon component={SaveIconZepetoWeb} />
          <span style={{ color: '#fff', lineHeight: 22 }}>Export as</span>
          <DownOutlined style={{ color: '#fff' }} />
        </Button>
      </Dropdown>
      <InfoCircleOutlined
        onClick={showZepetoInstructions}
        style={{ fontSize: 20, height: 24 }}
      />
    </div>
  );
}

const MemoUnityExportButtons = memo(UnityExportButtons);

export const NavigationBar: React.FC<Props> = ({
  isSignIn,
  userEmail,
  setIsSaveScene,
  isExportScene,
  setIsExportScene,
  exportSceneProgress,
  isLoginOpen = false,
  setIsLoginOpen = () => {},
  setIsTourOpen = () => {},
  ref1,
  ref2,
  ref3,
  ref4,
  ref9,
  ref10,
  isCreator = false,
  externalLogoURL,
}) => {
  const router = useRouter();
  const [isSaveUnityModalOpen, setIsSaveUnityModalOpen] = useState(false);
  const [inEditor, setInEditor] = useState(false);
  const [isZepetoInstructionsVisible, setZepetoInstructionsVisible] =
    useState(false);

  const branding = useContext(BrandingContext);

  const showZepetoInstructions = useCallback(
    () => setZepetoInstructionsVisible(true),
    []
  );

  const handleOzonePackageExport = useCallback(() => {
    if (!setIsExportScene) {
      return;
    }
    setIsExportScene('ozonepackage');
    setIsSaveUnityModalOpen(true);
    trackUmamiEvent('Editor: Export as ZepetoWeb');
  }, [setIsExportScene, IS_PROD]);

  const sceneId = Array.isArray(router.query?.id)
    ? router.query?.id[0]
    : router.query?.id;

  useEffect(() => {
    if (
      router.pathname === '/user/studio/scene/[id]' ||
      router.pathname === '/new-template'
    ) {
      setInEditor(true);
    }
  });

  return (
    <div className={styles.container}>
      <div
        style={{ height: '100%' }}
        className={inEditor ? styles.rootEditor : styles.root}
      >
        <div className={styles.left}>
          <Logo
            styles={styles}
            inEditor={inEditor}
            externalLogoURL={externalLogoURL}
          />
        </div>
        <div className={styles.profileCol}>
          {inEditor && (
            <div className={styles.editorButtons} id='editorButtons'>
              <NavigationButtonsGroup
                isSignIn={isSignIn}
                sceneId={sceneId}
                setIsSaveScene={setIsSaveScene}
                ref1={ref1}
                ref2={ref2}
                ref3={ref3}
              />
              {UNITY_EXPORT && branding === 'UNITY' && 'ZEPETO' && (
                <Flex ref={ref9} align='center'>
                  {isExportScene ? (
                    <MemoExportSceneProgress
                      exportSceneProgress={exportSceneProgress}
                    />
                  ) : (
                    <MemoUnityExportButtons
                      showZepetoInstructions={showZepetoInstructions}
                      handleOzonePackageExport={handleOzonePackageExport}
                    />
                  )}
                  <Divider type='vertical' className={styles.divider} />
                </Flex>
              )}
            </div>
          )}
        </div>
        <ProfileButtonsGroup
          inEditor={inEditor}
          isSaveUnityModalOpen={isSaveUnityModalOpen}
          isSignIn={isSignIn}
          styles={styles}
          userEmail={userEmail}
          isLoginOpen={isLoginOpen}
          setIsTourOpen={setIsTourOpen}
          setIsLoginOpen={setIsLoginOpen}
          setIsSaveUnityModalOpen={setIsSaveUnityModalOpen}
        />
      </div>
      <ZepetoInstructionsModal
        isOpen={isZepetoInstructionsVisible}
        setIsOpen={setZepetoInstructionsVisible}
      />
    </div>
  );
};
