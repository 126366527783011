import React, { memo, useContext } from 'react';
import { BrandingContext } from 'pages/_app';
import { S3_IMAGES_URL } from 'util/constants';
import StudioLogo from '@ozoneuniverse/ui-components/build-pkg/src/components/logo/StudioLogo';
import NavigationLogoSection from './zepeto/ZepetoLogo';
import NavigationUnityLogoSection from './unity-web-builder/UnityLogo';

const Logo = ({ styles, inEditor, externalLogoURL }) => {
  const branding = useContext(BrandingContext);

  if (branding == 'ZEPETO') {
    return <NavigationLogoSection styles={styles} />;
  }

  if (inEditor && branding == 'UNITY') {
    return <NavigationUnityLogoSection styles={styles} />;
  }

  return (
    <StudioLogo
      s3ImagesUrl={S3_IMAGES_URL as string}
      inEditor={inEditor}
      externalLogoURL={externalLogoURL}
    />
  );
};

export default memo(Logo);
